import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "twin.macro";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PageHero from "../components/PageSections/PageHero";
import Contact from "../components/PageSections/ContactUs";
import GetInvolved from "../components/PageSections/GetInvolved";
import TwoPanels from "../components/PageSections/TwoPanels";
import ExpandListItem from "../components/PageSections/ExpandListItem";
import CopyAndButton from "../components/PageSections/CopyAndButton";

const List = styled.ul`
  padding-top: 6px;

  @media (min-width: 768px) {
    padding-top: 25px;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

const IntBridgeProject = styled.section`
  padding: 60px 48px;

  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    max-width: 850px;
    margin: 0 auto;
  }

  h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.16px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    letter-spacing: -0.27px;
  }

  .copy {
    margin-top: 24px;
  }

  @media (min-width: 640px) {
    h2 {
      font-size: 48px;
    }
  }

  @media (min-width: 768px) {
    padding: 90px 118px;

    h2 {
      font-size: 60px;
    }

    h3 {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.71px;
      margin-bottom: 30px;
    }

    p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.36px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: 1024px) {
    padding: 110px 0px;
    max-width: 850px;
    margin: 0 auto;

    h2 {
      font-size: 90px;
    }

    h3 {
      font-size: 38px;
      line-height: 47px;
      letter-spacing: -0.71px;
    }

    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.41px;
      margin-bottom: 30px;
    }

    .copy {
      margin-top: 60px;
    }
  }
`;

const ForAdvocatesPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "for-advocates" } }) {
        nodes {
          title
          slug
          acf {
            hero {
              art {
                source_url
              }
              artist {
                age
                credit
              }
              title
              copy
              cta_type
              cta_text
              cta_link
            }
            local_partners {
              left_column
              right_column {
                title
                copy
              }
            }
            international_partners {
              left_column
              right_column
            }
            international_bridge_project {
              header
              copy
            }
            family_bridge_toolkit {
              title
              content
              download_link {
                title
                file {
                  url {
                    source_url
                  }
                }
              }
            }
            contact
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);
  const forAdvocatesData = data?.allWordpressPage?.nodes[0];
  const { yoast_meta, title } = forAdvocatesData;

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  const {
    hero,
    local_partners,
    international_partners,
    international_bridge_project,
    family_bridge_toolkit,
    contact
  } = forAdvocatesData?.acf;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...hero} />
      <TwoPanels
        left={local_partners?.left_column}
        list={true}
        right={
          <List>
            {local_partners.right_column.map((item, index) => (
              <ExpandListItem
                copy={item.copy}
                title={item.title}
                title_has_html
                key={index}
              />
            ))}
          </List>
        }
      />
      <TwoPanels
        left={international_partners?.left_column}
        right={international_partners?.right_column}
      />
      <div className="section-border">
        <IntBridgeProject>
          <div className="container">
            <h2>{international_bridge_project.header}</h2>
            <div
              className="copy"
              dangerouslySetInnerHTML={{
                __html: international_bridge_project.copy
              }}
            ></div>
          </div>
        </IntBridgeProject>
      </div>
      <CopyAndButton
        title={family_bridge_toolkit?.title}
        content={family_bridge_toolkit?.content}
        button={family_bridge_toolkit?.download_link}
      />
      <Contact content={contact} />
      <GetInvolved />
    </Layout>
  );
};

export default ForAdvocatesPage;
