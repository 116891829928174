import React, { useEffect } from "react";
import FileSaver from "file-saver";
import { styled } from "twin.macro";
import { Link } from "gatsby";
import Button from "../PageComponents/Button";

const TextAndButton = styled.section`
  // border-top: 1px solid rgba(124, 135, 149, 0.3);
  text-align: center;
  padding: 54px 48px;

  @media (min-width: 768px) {
    padding: 89px 119px;
  }

  @media (min-width: 1024px) {
    padding: 120px 188px;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
  }

  @media (min-width: 1024px) {
    font-size: 90px;
    line-height: 91px;
    letter-spacing: -4.11px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  margin-bottom: 27px;
  p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.27px;
    margin-top: 14px;
  }

  @media (min-width: 768px) {
    margin-bottom: 32px;
    p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.36px;
      margin-top: 19px;
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 40px;
    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.41px;
      margin-top: 22px;
    }
  }
`;

const CopyAndButtonComponent = ({ title, content, button, onClick }) => {
  // inputs include title, content, & button
  // `button` is an object that can either contain a 'file' or 'url' property
  // either download a file or link to another page

  // also, if there is an onClick passed down, perform that onClick
  // onClick will be an object:
  // onClick = {
  //   function,
  //   text
  // }

  const downloadButton = button.hasOwnProperty("file");
  const linkButton = button.hasOwnProperty("url");

  const handleDownloadClick = () => {
    const file = button.file.url.source_url;
    const name = file.split("/")[file.split("/").length - 1];
    FileSaver.saveAs(file, name);
  };
  return (
    <TextAndButton className="section-border">
      {title && <Title>{title}</Title>}
      <Content dangerouslySetInnerHTML={{ __html: content }} />
      {downloadButton && (
        <Button style="dark" onClick={handleDownloadClick}>
          {button.title}
        </Button>
      )}
      {linkButton && <Link to={`${button.url}/`}>{button.title}</Link>}
      {onClick && (
        <Button style="dark" onClick={onClick.function}>
          {onClick.text}
        </Button>
      )}
    </TextAndButton>
  );
};

export default CopyAndButtonComponent;
